<template>
  <div id="base-input">
    <div class="lp-title">
      <div>
        {{ title }}
      </div>
    </div>
    <div id="form-input">
      <base-input
        v-model="input.keyword"
        :name="input.name"
        :placeholder="input.placeHolder"
        @keypress.enter="handleInput"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/new-input/Input.vue";
import mixinMobile from "@/misc/mixinMobile"

export default {
  name: "InputSearchFAQ",
  components: {BaseInput},
  mixins: [mixinMobile],
  data() {
    return {
      title: "Butuh Bantuan?",
      input: {
        name: "InputSearchFAQ",
        placeHolder: "Cari issue yang dibutuhkan...",
        keyword: this.$route.query.search,
      },
      searched: false,
    }
  },
  computed: {},
  methods: {
    handleInput() {
      if(this.input.keyword) {
        this.searched = true;
        window.history.replaceState(null, null, '?page=1&search=' + this.input.keyword);
      }
      else {
        this.searched = false;
        if(this.$route.path === '/faq') window.history.replaceState(null, null, this.$route.path);
        else window.history.replaceState(null, null, this.$route.path + '?page=1');
      }
      this.$emit('searchedValue', { searched:this.searched, keyword:this.input.keyword });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style";

#base-input {
  z-index: 1;
  position: relative;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  padding: 20px 40px 31px 40px;
  
  @include for-size(mobile) {
    padding: 20px 20px 31px 22px;
  }

  > div {
    margin-bottom: .5%;
  }

  .lp-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #4D4D4D;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include for-size(mobile) {
      font-size: 16px;
    }
  }
}

#form-input {
  width: 100%;
}
</style>
