<template>
  <div>
    <bread-crumb class="top-left" />
    <!-- Banner -->
    <div class="banner-new-faq do-flex">
      <div class="banner-new-faq__text do-flex-col">
        <div class="banner-new-faq__text__can-help">
          Masih Bingung?
        </div>
        <div class="banner-new-faq__text__can-help2">
          Cari & Temukan Solusi Di FAQ
        </div>
      </div>
      <image-lazy
        :src="imgSrcBanner"
        alt="faq_banner.png"
        @error="(event) => setErrorImage(event, 'faq-banner')"
      />
    </div>
    <!-- Banner -->

    <!-- Search Box -->
    <div class="faq-input__wrapper">
      <div class="faq-input__wrapper__box-search">
        <input-search @searchedValue="searchValue" />
      </div>
    </div>
    <!-- Search Box -->
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import InputSearch from '../components/InputSearch';
import mixinMobile from '@/misc/mixinMobile';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  name: 'HeaderFAQ',
  components: {
    BreadCrumb,
    InputSearch,
    ImageLazy,
  },
  mixins: [mixinMobile],
  data() {
    return {};
  },
  computed: {
    imgSrcBanner() {
      return this.windowWidth <= 820
        ? '/images/faq/banner_liona_mobile.webp'
        : '/images/faq/banner_liona.webp';
    },
  },
  methods: {
    searchValue(searchVal) {
      this.$emit('searchValue', searchVal);
    },
    setErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style";
@import "../../../assets/css/flexbox";

.top-left {
  z-index: 2;
  position: absolute;
  margin-top: 25px;

  @media (min-width: 600px) and (max-width: 820px) {
    margin-top: 30px;
  }
}

.banner-new-faq {
  margin-top: 20px;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  align-items: center;

  &__text {
    z-index: 1;
    position: absolute;
    left: 8%;
    color: $color-base-text;

    @include for-size(mobile) {
      left: unset;
      right: 10%;
    }

    @media (min-width: 600px) and (max-width: 820px) {
      left: unset;
      right: 10%;
    }

    &__can-help {
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;

      @include for-size(mobile) {
        font-size: 18px;
        line-height: 20px;
      }

      @media (min-width: 600px) and (max-width: 820px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    &__can-help2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;

      @include for-size(mobile) {
        font-size: 13px;
        line-height: 20px;
      }

      @media (min-width: 600px) and (max-width: 820px) {
        font-size: 24px;
      }
    }
  }

  > div:last-child {
    min-height: 200px;
    width: 100%;
  }

}

.faq-input {
  &__wrapper {
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: -62px;
    justify-content: center;
    align-items: center;
    align-content: center;

    &__box-search {
      z-index: 2;
      position: relative;
      align-items: center;
      background-color: $color-white;
      box-shadow: 0 1px 4px 0 rgba(26, 20, 33, 0.15);
      color: $color-gray-dark-2;
      width: 85%;
      border-radius: 10px;
      border: $color-gray-light solid 1px;
    }
  }
}
.image-default__faq-banner {
  height: 380px;
  padding-top: 120px;
  text-align: center;

  @include image-default(auto, auto);
}
</style>
