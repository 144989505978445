const FAQ_CONTENT = [
  {
    label: 'BANTUAN',
    value: [
      {
        alias: 'jenis-pengiriman-&-layanan',
        name: 'produk',
      },
      {
        alias: 'pelacakan-paket',
        name: 'pelacakan-paket',
      },
      {
        alias: 'cek-tarif',
        name: 'cek-tarif',
      },
      {
        alias: 'pengemasan-paket',
        name: 'pengemasan',
      },
      {
        alias: 'pembayaran',
        name: 'pembayaran',
      },
      {
        alias: 'asuransi',
        name: 'asuransi',
      },
      {
        alias: 'klaim-paket',
        name: 'klaim-paket',
      },
      {
        alias: 'agen',
        name: 'keagenan',
      },
      {
        alias: 'korporasi',
        name: 'korporasi',
      },
      {
        alias: 'dangerous-goods',
        name: 'dangerous-goods',
      },
      {
        alias: 'merchant-bisaBelanja',
        name: 'merchant-bisabelanja',
      },
      {
        alias: "cash-on-delivery",
        name: "cash-on-delivery",
      },
    ],
  },
  {
    label: 'APPS',
    value: [
      {
        alias: 'pick-up',
        name: 'pick-up',
      },
      {
        alias: 'drop-paket',
        name: 'drop-paket',
      },
      {
        alias: 'cek-tarif',
        name: 'cek-tarif',
      },
      {
        alias: 'klaim-paket',
        name: 'klaim-paket',
      },
      {
        alias: 'bisaBelanja',
        name: 'bisabelanja',
      },
      {
        alias: 'poin-&-saldo',
        name: 'poin-&-saldo',
      },
      {
        alias: 'membership',
        name: 'membership',
      },
      {
        alias: 'paketmu',
        name: 'paketmu',
      },
      {
        alias: 'pembayaran',
        name: 'pembayaran',
      },
      {
        alias: 'akun',
        name: 'akun',
      },
    ],
  },
];

module.exports = FAQ_CONTENT;
