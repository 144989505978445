<template>
  <div class="base-card">
    <div
      class="wrapper-img"
      :style="{ backgroundColor: bgColor }"
    >
      <img
        :src="src"
        :alt="title + '.png'"
      >
    </div>
    <div class="text_frame">
      <div class="text_frame__title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  props: {
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    topic: {
      type: String,
      required: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";

.base-card {
  background: #FFFFFF;
  box-shadow:  0 1px 4px 0 rgba(26, 20, 33, 0.15);
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 175px;
  height: 175px;
  cursor: pointer;

  @include for-size(mobile) {
    width: 150px;
    height: 170px;
  }

  .text_frame {
      margin: auto;
      height: 48px;
      width: 145px;

      @include for-size(mobile) {
        margin-top: 20px;
        height: 42px;
        width: 125px;
      }

      &__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;

        @include for-size(mobile) {
            font-size: 14px;
            line-height: 21px;
        }
      }
  }

  .wrapper-img {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;

    img {
      margin: auto;
      width: 32px;
      height: 32px;
    }
  }
}
</style>
