const FAQ_DETAIL_CONTENT = {
  BANTUAN: {
    produk: {
      src: 'faq/bantuan/produk.svg',
      color: '#FEFAE8',
      title: 'Jenis Pengiriman & Layanan',
    },
    'pelacakan-paket': {
      src: 'faq/bantuan/kirim.svg',
      color: '#D8EEFB',
      title: 'Pelacakan Paket',
    },
    'cek-tarif': {
      src: 'faq/bantuan/hitung.svg',
      color: '#D8EEFB',
      title: 'Cek Tarif',
    },
    pengemasan: {
      src: 'faq/bantuan/kemas.svg',
      color: '#D8EEFB',
      title: 'Pengemasan Paket',
    },
    pembayaran: {
      src: 'faq/bantuan/bayar.svg',
      color: '#E3FCEF',
      title: 'Pembayaran',
    },
    asuransi: {
      src: 'faq/bantuan/asuransi.svg',
      color: '#E3FCEF',
      title: 'Asuransi',
    },
    'klaim-paket': {
      src: 'faq/bantuan/klaim.svg',
      color: '#E3FCEF',
      title: 'Klaim Paket',
    },
    keagenan: {
      src: 'faq/bantuan/agen.svg',
      color: '#E9E7FD',
      title: 'Agen'
    },
    korporasi: {
      src: 'faq/bantuan/korporasi.svg',
      color: '#E9E7FD',
      title: 'Korporasi',
    },
    'dangerous-goods': {
      src: 'faq/bantuan/dangerous.svg',
      color: '#EBECF0',
      title: 'Dangerous Goods',
    },
    'merchant-bisabelanja': {
      src: 'faq/bantuan/bisabelanja.svg',
      color: '#FEE8E8',
      title: 'Merchant BisaBelanja',
    },
    'cash-on-delivery': {
      src: 'faq/bantuan/cod.svg',
      color: '#FEE8E8',
      title: 'Cash On Delivery',
    },
  },
  APPS: {
    'pick-up': {
      src: 'faq/apps/pick.svg',
      color: '#FEE8E8',
      title: 'Pick Up'
    },
    'drop-paket': {
      src: 'faq/apps/drop.svg',
      color: '#FEE8E8',
      title: 'Drop Paket',
    },
    'cek-tarif': {
      src: 'faq/apps/cek.svg',
      color: '#FEE8E8',
      title: 'Cek Tarif',
    },
    'klaim-paket': {
      src: 'faq/apps/klaim.svg',
      color: '#FEE8E8',
      title: 'Klaim Paket',
    },
    bisabelanja: {
      src: 'faq/apps/bisabelanja.svg',
      color: '#FEE8E8',
      title: 'BisaBelanja',
    },
    'poin-&-saldo': {
      src: 'faq/apps/poin.svg',
      color: '#FEE8E8',
      title: 'Poin & Saldo',
    },
    membership: {
      src: 'faq/apps/member.svg',
      color: '#FEE8E8',
      title: 'Membership',
    },
    paketmu: {
      src: 'faq/apps/paketmu.svg',
      color: '#FEE8E8',
      title: 'Paketmu',
    },
    pembayaran: {
      src: 'faq/apps/pembayaran.svg',
      color: '#FEE8E8',
      title: 'Pembayaran',
    },
    akun: {
      src: 'faq/apps/akun.svg',
      color: '#FEE8E8',
      title: 'Akun'
    },
  },
};

module.exports = { FAQ_DETAIL_CONTENT };
