import InternalAPI from '../../../../app/baseAxios.js';
import '../types';


/** @borrows InternalAPI */
export default class FAQRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v2/faq/data';
  }

  /**
     *
     * @param page {number}
     * @param perPage {number}
     * @param status {string}
     * @return {Promise<{data: FAQ[], err: Error, pagination: pagination{}}>}
     */
  async getAll(queryParam) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${this.endpoint}${queryParam}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data.data, err: null, pagination: res.data.data.pagination };
        }
      }
      return { data: null, err: null, pagination: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response), pagination: null };
    }
  }
}
