import FAQRepository from '../api/faq.js';
import '../types';

class FAQUseCase {
  constructor() {
    /** @type {FAQRepository} */
    this.api = new FAQRepository();
  }

  /**
     *
     * @param page {number}
     * @param perPage {number}
     * @param status {string}
     * @return {Promise<{data: FAQ[], err: Error, pagination: pagination{}}>}
     */
  async getAll(page = 1, perPage = 15) {
    const queryParam = `?page=${page}&per_page=${perPage}`;
    const { data, err, pagination } = await this.api.getAll(queryParam);
    if (err && err == 'record not found') {
      return { data: null, err: null, pagination: null };
    }
    return { data, err, pagination };
  }

  /**
     *
     * @param page {number}
     * @param perPage {number}
     * @param priority {string}
     * @return {Promise<{data: FAQ[], err: Error, pagination: pagination{}}>}
     */
  async getPriority(page, perPage, priority) {
    const queryParam = `?page=${page}&per_page=${perPage}&priority=${priority}`;
    const { data, err, pagination } = await this.api.getAll(queryParam);
    if (err && err == 'record not found') {
      return { data: null, err: null, pagination: null };
    }
    return { data, err, pagination };
  }

  /**
     *
     * @param topic
     * @return {string}
     */
  getAlias(topic) {
    if (topic === 'jenis-pengiriman-&-layanan') {
      return 'produk';
    } if (topic === 'agen') {
      return 'keagenan';
    } if (topic === 'pengemasan-paket') {
      return 'pengemasan';
    }
    return topic;
  }

  /**
     *
     * @param page {number}
     * @param perPage {number}
     * @param category {string}
     * @param topic {string}
     * @return {Promise<{data: FAQ[], err: Error, pagination: pagination{}}>}
     */
  async getPerCategoryTopic(page, perPage, category, topic) {
    const xtopic = this.getAlias(topic.toLowerCase());
    const queryParam = `?page=${page}&per_page=${perPage}&category=${category}&topic=${xtopic}`;
    const { data, err, pagination } = await this.api.getAll(queryParam);
    if (err && err == 'record not found') {
      return { data: null, err: null, pagination: null };
    }
    return { data, err, pagination };
  }

  /**
     *
     * @param page {number}
     * @param perPage {number}
     * @param question {string}
     * @return {Promise<{data: FAQ[], err: Error, pagination: pagination{}}>}
     */
  async getSearched(page, perPage, question) {
    const queryParam = `?page=${page}&per_page=${perPage}&question=${question}`;
    const { data, err, pagination } = await this.api.getAll(queryParam);
    if (err && err == 'record not found') {
      return { data: null, err: null, pagination: null };
    }
    return { data, err, pagination };
  }

  /**
     *
     * @param page {number}
     * @param perPage {number}
     * @param category {string}
     * @param topic {string}
     * @param question {string}
     * @return {Promise<{data: FAQ[], err: Error, pagination: pagination{}}>}
     */
  async getSearchedPerCategoryTopic(page, perPage, category, topic, question) {
    const queryParam = `?page=${page}&per_page=${perPage}&category=${category}&topic=${topic}&question=${question}`;
    const { data, err, pagination } = await this.api.getAll(queryParam);
    if (err && err == 'record not found') {
      return { data: null, err: null, pagination: null };
    }
    return { data, err, pagination };
  }
}

export default new FAQUseCase();
