<template>
  <div class="notFound">
    <div class="img">
      <img
        :src="`/images/faq/notfound.svg`"
        alt="notfound.png"
      >
    </div>
    <div class="text">
      <div class="title">
        Hasil Pencarian Tidak Ditemukan
      </div>
      <div class="desc">
        Pastikan kata kunci yang kamu cari sudah sesuai atau hubungi CS untuk informasi lebih lanjut
      </div>
    </div>
    <TheButton
      class="button"
      :custom-padding="true"
      text="Hubungi CS Lion Parcel"
      img="headphone.svg"
      @click="triggerToCS()"
    />
  </div>
</template>

<script>
import TheButton from "../../../components/new-button/Button";

export default {
  name: "NotFound",
  components: {TheButton},
  methods: {
    triggerToCS() {
        window.open("https://wa.me/6281119600999");
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/style";

.notFound {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text{
  max-width: 500px;
  margin: auto;

  @include for-size(mobile) {
    max-width: 300px;
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #4D4D4D;
  margin-bottom: 8px;
}

.desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #A7A8AA;
  margin-bottom: 24px;
}

.button {
    margin: auto;
    width: 200px;
    height: 40px;
    padding: 9px, 16px, 9px, 16px;
    font-size: 12px;
}
</style>